import React from 'react';
import SolutionPage from '../../components/SolutionPage';

const data = {
    heroSection: {
        label: {
            title: 'Part Assembly & Packaging', 
            imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-assembly-label-logo.png', 
            imgDimensions: {
                width: '0.875rem', 
                height: '0.875rem'
            }, 
            mobileImgDimensions: {
                width: '0.5rem', 
                height: '0.5rem' 
            }
        }, 
        heading: [
            {
                text: 'Get exact count of parts', 
                textColor: 'blue-800'
            },
            {
                text: 'each time & instantly', 
                textColor: 'black'
            }
        ],  
        content: "Use Loopr's counting and sorting AI software to replace slow and approximate manual or weight-based part counting.", 
        videoSrc: 'https://www.youtube.com/embed/-MXDAemBXWo'
    }, 
    problemsSection: {
        heading: 'Problems with manual part counting', 
        content: [
            {
                title: ['Tedious'], 
                text: 'Counting parts manually is slow and error prone for human operators', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/tedious-icon.png'
            },
            {
                title: ['Approximate'], 
                text: 'Weight based counting is inaccurate and unreliable', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/approximate-icon.png'
            },
            {
                title: ['High Costs'], 
                text: 'Counting errors during assembly or packaging incurs high costs at scale', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/high-costs-icon.png'
            }
        ]   
    }, 
    outcomesSection: {
        content: [
            {
                title: 'Precision', 
                text: 'Ensure exact count of parts in every assembled product or package', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/precision-icon.png', 
                imgDimensions: {
                    width: '3.56rem', 
                    height: '3.63rem'
                }
            },
            {
                title: 'Lower costs', 
                text: 'Save costs by reducing waste of raw material or shipping extra parts in a package', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/lower-cost-icon.png', 
                imgDimensions: {
                    width: '3.72rem', 
                    // height: '3.48rem'
                    height: '3.63rem'
                }
            },
            {
                title: 'Increased Output', 
                text: 'Increase speed of assembly and packaging with instant part counting', 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/increased-output-icon.png', 
                imgDimensions: {
                    width: '3.12rem', 
                    // height: '4.22rem'
                    height: '3.63rem'
                }
            },
        ] 
    },
    automateSection: {
        heading: ['Automate part counting with', 'easy to use AI software'], 
        content: [
            {
                id: '01', 
                title: 'Customized for your parts', 
                text: [
                    "Loopr's camera-based software is customized to recognize your raw materials, finished parts, packaging, etc ",
                    'Software can detect material and parts of all shapes, sizes, and colors, which a human inspector can identify'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-assembly-automate-01.png'
            },
            {
                id: '02', 
                title: 'Accurate results each time', 
                text: [
                    'Exact number of parts packaged or under assembly are counted instantly and displayed on a screen',
                    "Get a consistent and quick count each time, without relying solely on a quality operator's experience or fatigue level"
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-assembly-automate-02.png'
            },
            {
                id: '03', 
                title: 'Instant alerts', 
                text: [
                    'Define number of parts and materials required for each assembly or packaging',
                    'Alert is raised if too many or too few parts are used which ensures tight production control'
                ], 
                imgUrl: 'https://looprdevstorage.blob.core.windows.net/website-temp/part-assembly-automate-03.png'
            },
        ]
    }, 
    platformSection: true
}

export default function PartAssemblyPage(){
    return  <SolutionPage data={data} />
}